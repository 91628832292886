<template>
  <section class="expressions">
    <div class="expressions-wrapper">
      <md-card class="card-showbox">
        <md-card-content v-if="getUserImage">
          <img :src="getUserImage" alt="Previously inserted face">
        </md-card-content>
        <md-card-content v-else>
          <router-link :to="{path: '/main'}">Please provide an initial face.</router-link>
        </md-card-content>
        <md-card-actions>
          <span>Face Pic</span>
        </md-card-actions>
      </md-card>
      <md-card class="card-showbox">
        <md-card-content>
          <FilePond
            ref="expressionsFilePond"
            label-idle="Add your expression here 🤳"
            @updatefiles="onFile"
            @processfiles="onProcess"
            v-if="!getZipDirectory"
            :allow-process="false"
            instant-upload="false"
            accepted-file-types="image/jpg, image/png"
          />
        </md-card-content>
        <md-card-actions>
          <span>Expressions Pic</span>
        </md-card-actions>
      </md-card>
    </div>
    <div class="actions-wrapper">
      <button class="raised-btn gradient-action-btn no-pulse" :disabled="btnDisabled">
        {{ loading ? 'Processing...' : 'Process with Expression 👻' }}
      </button>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

export default {
  name: 'Fac3dExpressions',
  components: {
    FilePond: vueFilePond(FilePondPluginImagePreview),
  },
  computed: {
    ...mapGetters(['getZipDirectory', 'getUserImage']),
    btnDisabled() {
      return this.loading || !this.getZipDirectory || !this.getUserImage;
    },
  },
  data() {
    return {
      expressionsImg: null,
      loading: false,
    };
  },
  methods: {
    onFile() {},
    onProcess() {},
  },
  mounted() {
    this.$nextTick(() => {
      const target = document.querySelector('.filepond--credits');
      if (target) target.style.display = 'none';
    });
  },
};
</script>
<style scoped lang="scss">
.expressions-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  .md-card-actions {
    span {
      width: 100%;
      text-align: center;
      font-size: 11px;
      color: #333333;
    }
  }
}
.actions-wrapper {
  margin-top: 4rem;
}
.card-showbox {
  width: 35%;
  height: 12rem;
}
</style>
